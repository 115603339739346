import Link from 'next/link';
import styled from 'styled-components';
import { H5 } from '@bscom/components/Headers';
import { Spacing } from '@bscom/components/Spacing';
import { Background } from '@bscom/components/Background';
import { desktopsLarge } from '@bscom/styling/breakpoints';
import { MAX_PAGE_WIDTH } from '@bscom/constants';
import { ResponsiveContentfulImage } from '@bscom/components/Image';
import { Iframe } from '@bscom/content-blocks/Iframe';

import FramedVideo from './video';
import FramedCalendar from './calendar';
import FramedSharePrice from './sharePrice';
import { FramedRegulatoryNews } from './regulatoryNews';
import { WidgetFrame, IFrameWrapper } from './styles';

const GridOutline = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${({ rowGap, columnGap }) => `
      row-gap: ${rowGap}px;
      column-gap: ${columnGap}px;
  `}

  @media(${desktopsLarge}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ImageWrapper = styled(WidgetFrame)`
  position: relative;
  overflow: hidden;
`;

const IframeWidget = ({ iframe, ...props }) => (
  <IFrameWrapper withPadding>
    <Iframe {...iframe} />
  </IFrameWrapper>
);

const LinkWrapper = ({ children, href, target, rel }) =>
  href ? (
    <Link href={href} target={target} ref={rel}>
      {children}
    </Link>
  ) : (
    children
  );

export const GridResponsiveImage = (props) => (
  <ResponsiveContentfulImage {...props} />
);

const ImageWidget = ({ image, url }) => {
  const isInternalLink = url && url.substring(0, 1) === '/';

  return (
    <LinkWrapper
      url={url}
      {...(!isInternalLink && { target: '_blank', rel: 'noreferrer' })}
    >
      <ImageWrapper>
        <GridResponsiveImage {...image} />
      </ImageWrapper>
    </LinkWrapper>
  );
};

const framedComponentMap = {
  framedContentGridItemIframe: IframeWidget,
  framedContentGridItemImage: ImageWidget,
  framedContentCalendar: FramedCalendar,
  framedContentVideo: FramedVideo,
};

const FramedItemHeader = styled(H5).attrs({ as: 'h3' })`
  margin-bottom: 20px;
`;

const FramedItem = (props) => {
  const { title, content } = props;
  let FramedComponent =
    framedComponentMap[content.sys.contentType.sys.id] ||
    (() => (
      <div>{`Component with content type ${content.sys.contentType.sys.id} not found.`}</div>
    ));

  if (
    content?.iframe?.url ===
    'https://polaris.brighterir.com/public/trustpilot/data_table/share_price_widget'
  )
    FramedComponent = FramedSharePrice;

  if (
    content?.iframe?.url ===
    'https://polaris.brighterir.com/public/trustpilot/news/rns_widget'
  )
    FramedComponent = FramedRegulatoryNews;

  return (
    <div>
      <FramedItemHeader>{title}</FramedItemHeader>
      <FramedComponent {...content} />
    </div>
  );
};

const FramedContentGrid = ({
  background,
  contentCollection,
  innerSpacingCollection,
  ...props
}) => {
  const rowGap = props.rowGap || 60;
  const columnGap = props.columnGap || 130;
  const spaces =
    (innerSpacingCollection && innerSpacingCollection?.items) || [];
  const items = (contentCollection && contentCollection?.items) || [];

  return (
    <Background {...background}>
      <Spacing withXPadding spaces={spaces} contentWidth={MAX_PAGE_WIDTH}>
        <GridOutline {...{ rowGap, columnGap }}>
          {items.map((item) => (
            <FramedItem key={item.title} {...item} />
          ))}
        </GridOutline>
      </Spacing>
    </Background>
  );
};

export const WrappedFramedContentGrid = (props) => {
  return <FramedContentGrid {...props} />;
};

export default WrappedFramedContentGrid;
