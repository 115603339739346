import styled from 'styled-components';
import { Iframe } from '@bscom/content-blocks/Iframe';
import { Title, Button, IFrameWrapper } from './styles';
import { phones, tablets, desktopsLarge } from '@bscom/styling/breakpoints';

import Link from 'next/link';

const FixHeight = styled.div`
  height: 150px;
  margin-bottom: 20px;
  @media (${tablets}) {
    height: 150px;
    position: relative;
  }

  @media (${desktopsLarge}) {
    height: 150px;
  }
`;

const CustomIFrameWrapper = styled(IFrameWrapper)`
  @media (${phones}) {
    height: fit-content;
  }
`;

export const FramedRegulatoryNews = ({ iframe }) => (
  <CustomIFrameWrapper withPadding>
    <Title>Regulatory Events</Title>
    <FixHeight>
      <Iframe {...iframe} />
    </FixHeight>
    <Button as={Link} href="/regulatory-news">
      View all
    </Button>
  </CustomIFrameWrapper>
);
