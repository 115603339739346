import styled from 'styled-components';
import { tablets, desktopsLarge } from '@bscom/styling/breakpoints';

export const WidgetFrame = styled.div`
  height: 244px;

  @media (${tablets}) {
    height: 440px;
  }

  @media (${desktopsLarge}) {
    height: 340px;
  }

  ${({ whiteBackground }) => whiteBackground && 'background: white;'}

  ${({ withPadding }) =>
    withPadding &&
    `
    padding: 25px;

    @media (${tablets}) {
      padding: 50px;
    }

    @media (${desktopsLarge}) {
      padding: 40px;
    }
  `}
`;

export const IFrameWrapper = styled(WidgetFrame)`
  background-color: white;

  iframe {
    height: 100%;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;

  @media (${tablets}) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 30px;
  }

  @media (${desktopsLarge}) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  border-radius: 2px;
  margin-top: 20px;
  font-weight: 500;
  cursor: pointer;
  background: white;
  border: 2px solid black;
  font-size: 14px;
  line-height: 22px;
  padding: 12px 42px;
  color: black;

  @media (${tablets}) {
    font-size: 13px;
    line-height: 21px;
    margin-top: 40px;
    padding: 10px 30px;
  }

  @media (${desktopsLarge}) {
    font-size: 11px;
    line-height: 16px;
    margin-top: 30px;
    padding: 6px 20px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;
