import Link from 'next/link';
import { Fragment } from 'react';
import sortBy from 'lodash.sortby';
import styled from 'styled-components';
import { tablets, desktopsLarge } from '@bscom/styling/breakpoints';
import { TABLET_WIDTH } from '@bscom/constants/widths';
import { WidgetFrame, Title, Button } from './styles';

const GridWrapper = styled.div`
  border-left: 4px solid black;
  padding-left: 15px;

  @media (${tablets}) {
    padding-left: 30px;
  }

  @media (${desktopsLarge}) {
    padding-left: 25px;
  }
`;

const Grid = styled.div`
  display: grid;
  row-gap: 10px;
  column-gap: 30px;
  grid-template-columns: min-content 1fr min-content;

  @media (max-width: ${TABLET_WIDTH - 1}px) {
    .hide-on-mobile {
      display: none;
    }
  }
`;

const Month = styled.h6`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  @media (${tablets}) {
    font-size: 12px;
    line-height: 15px;
  }

  @media (${desktopsLarge}) {
    font-size: 9px;
    line-height: 11px;
  }
`;

const Day = styled.h5`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0px;

  @media (${tablets}) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (${desktopsLarge}) {
    font-size: 15px;
    line-height: 18px;
  }
`;

const DateView = ({ event: { date }, ...props }) => (
  <div {...props}>
    <Month>{date.toString().split(' ')[1]}</Month>
    <Day>{date.getDate()}</Day>
  </div>
);

const ContentWrapper = styled.div`
  overflow: hidden;
`;

const ContentDetails = styled.h6`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (${tablets}) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (${desktopsLarge}) {
    size: 9px;
    line-height: 10px;
  }
`;

const ContentTitle = styled.h5`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (${tablets}) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (${desktopsLarge}) {
    font-size: 15px;
    line-height: 18px;
  }
`;

const NeutralLink = styled(Link)`
  color: black;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const WrapInNeutralLink = ({ children, url }) => {
  return url ? <NeutralLink href={url}>{children}</NeutralLink> : children;
};

const Content = ({ event: { date, location, title, url }, ...props }) => (
  <ContentWrapper {...props}>
    <ContentDetails>{location}</ContentDetails>
    <WrapInNeutralLink url={url}>
      <ContentTitle>{title}</ContentTitle>
    </WrapInNeutralLink>
  </ContentWrapper>
);

const Border = styled.div`
  grid-column-start: span 3;
  border-top: 1px solid black;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = ({ event: { url }, ...props }) =>
  url ? (
    <ArrowWrapper {...props}>
      <WrapInNeutralLink url={url}>
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.56696 0.826172L4.38145 1.92075L7.47808 4.77986H0V6.34444H7.62652L4.38145 9.34029L5.56696 10.4349L10.7703 5.63068L5.56696 0.826172Z"
            fill="#1B1B21"
          />
        </svg>
      </WrapInNeutralLink>
    </ArrowWrapper>
  ) : null;

const FramedCalendar = ({ events = [] }) => (
  <WidgetFrame whiteBackground withPadding>
    <Title>Upcoming Events</Title>
    {events.length === 0 ? (
      <h3>No upcoming events.</h3>
    ) : (
      <>
        <GridWrapper>
          <Grid>
            {sortBy(events, 'date')
              .slice(0, 3)
              .map((event, index) => {
                const hideOnMobile = events.length > 2 &&
                  index === events.length - 1 && {
                    className: 'hide-on-mobile',
                  };

                return (
                  <Fragment key={index}>
                    <DateView {...hideOnMobile} event={event} />
                    <Content {...hideOnMobile} event={event} />
                    <Arrow {...hideOnMobile} event={event} />
                    {events.length - 1 !== index && (
                      <Border
                        {...(index === events.length - 2 && {
                          className: 'hide-on-mobile',
                        })}
                      />
                    )}
                  </Fragment>
                );
              })}
          </Grid>
        </GridWrapper>
        <Link href="/investor/investor-information/financial-calendar">
          <Button>View all</Button>
        </Link>
      </>
    )}
  </WidgetFrame>
);

const FramedCalendarContainer = ({
  table,
  tableEventName,
  tableEventLocation,
  tableEventLink,
}) => {
  const getHeaderIndex = (header) =>
    (table?.tableHeaderCollection?.items || [])
      .map(({ title }) => title)
      .indexOf(header.title);

  const eventNameIndex = getHeaderIndex(tableEventName);
  const eventLocationIndex = getHeaderIndex(tableEventLocation);
  const eventLinkIndex = getHeaderIndex(tableEventLink);

  const events =
    eventNameIndex === -1 || eventLocationIndex === -1
      ? []
      : (table?.rowsCollection?.items || []).map(
          ({ columnsCollection, date }) => ({
            date: new Date(date),
            title: columnsCollection.items[eventNameIndex].columnText,
            location: columnsCollection.items[eventLocationIndex].columnText,
            url: columnsCollection?.items[eventLinkIndex]?.mediasCollection
              ?.items[0]?.url,
          })
        );

  return <FramedCalendar events={events} />;
};

export default FramedCalendarContainer;
