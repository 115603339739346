import Head from 'next/head';
import { ImageWrapper, GridResponsiveImage } from './grid';

const Video = ({ image, url, ...props }) => {
  const splitUrl = url.split('/');
  const videoId = splitUrl[splitUrl.length - 1];

  return (
    <>
      <Head>
        <script src={url} async></script>
        <script
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
        ></script>
      </Head>
      <span
        className={`wistia_embed wistia_async_${videoId} popover=true popoverContent=link`}
      >
        <ImageWrapper>
          <a href="#">
            <GridResponsiveImage {...image} />
          </a>
        </ImageWrapper>
      </span>
    </>
  );
};

export default Video;
