import styled from 'styled-components';
import { IFrameWrapper } from './styles';
import { Iframe } from '@bscom/content-blocks/Iframe';
import { phones, tablets, desktopsLarge } from '@bscom/styling/breakpoints';

const FixHeight = styled.div`
  height: 238px;

  @media (${tablets}) {
    height: 238px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;
  }

  @media (${desktopsLarge}) {
    height: 100%;
  }

  @media (max-width: 580px) {
    height: 322px;
  }
`;

const CustomIFrameWrapper = styled(IFrameWrapper)`
  @media (${phones}) {
    height: fit-content;
  }

  @media (max-width: 580px) {
    iframe {
      height: 322px;
    }
  }
`;

const SharePrice = ({ iframe, ...props }) => (
  <CustomIFrameWrapper withPadding>
    <FixHeight>
      <Iframe {...iframe} />
    </FixHeight>
  </CustomIFrameWrapper>
);

export default SharePrice;
